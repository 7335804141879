import { useEffect, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  blurSelector,
  IReport,
  loadOverallMinutes,
  loadReportsByDate,
  loadResultReports,
  loadUserOverallMinutes,
  paginatedReportsSelector,
  reportsSelector,
  usersSelector,
} from '@/redux';
import { Periods } from '@/types/periods';
import api from '@/services/api';
import {
  IDateRange,
  IDisplayHours,
  IFormatFromPeriod,
  IHours,
  IOverallMinutes,
} from '@/interfaces';
import { DateFormatUtil } from '@/utils';
import dateUtils from '@/utils/dates';
import { userRoles } from '@/constants';
import {
  endOfMonth,
  endOfWeek,
  endOfYear,
  format as formatDate,
  getDaysInMonth,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from 'date-fns';
import { IOverallCardReport } from '@/components/Cards/types';
import { IApplicationState } from '@/redux/types';

const getTime = (data: string | number) => {
  const time = typeof data === 'string' ? parseInt(data, 10) : data;

  if (!time || time <= 0) {
    return 0;
  }

  const hours = dateUtils.formattedTime(Math.floor(time / 60));
  const minutes = dateUtils.formattedTime(time % 60);
  return `${hours}:${minutes}`;
};

const useOverallCard = (
  period: Periods,
  overallMinutes: IOverallMinutes | null,
  userRole: string | undefined,
  year: number,
  month: number,
  isDesktop?: boolean,
) => {
  const dispatch = useDispatch();

  const router = useRouteMatch<{ id: string }>();
  const users = useSelector(usersSelector);

  const currentUser = useMemo(
    () => users.find((user) => user.id === router.params.id) ?? users[0],
    [router.params.id, users],
  );

  const overallHours = useMemo(() => {
    const formatFromPeriod: IFormatFromPeriod[] = [
      {
        period: Periods.Month,
        format: (date: string) => {
          return DateFormatUtil.formatToMonth(date);
        },
      },
      {
        period: Periods.Year,
        format: (date: string) => {
          return DateFormatUtil.formatToYear(date);
        },
      },
      {
        period: Periods.Week,
        format: (date: string) => {
          return isDesktop
            ? DateFormatUtil.formatToWeek(date)
            : DateFormatUtil.formatToShortWeek(date);
        },
      },
    ];

    return overallMinutes
      ? overallMinutes.filterDate.map((el) => {
          return {
            date: formatFromPeriod.find((format) => format.period === period)?.format(el.date),
            workedHours: (parseInt(el.workedMinutes, 10) / 60).toString(),
            isVacation: el.isVacation,
          };
        })
      : [];
  }, [overallMinutes, period, isDesktop]);

  const allMonth = useMemo(() => {
    return overallHours.reduce((acc: IDisplayHours, el: IHours) => {
      if (!el.date) {
        return acc;
      }
      const value = {
        date: el.date,
        workedHours: el.workedHours,
      };
      if (acc[el.date]) {
        acc[el.date].push(value);
        return acc;
      }

      return {
        ...acc,
        [el.date]: [value],
      };
    }, {});
  }, [overallHours]);

  const sumOfMonth = useMemo(() => {
    return Object.entries(allMonth).map(([, hours]) =>
      hours.reduce((acc, item) => {
        return {
          date: item.date,
          workedHours: (parseFloat(acc.workedHours) + parseFloat(item.workedHours)).toString(),
        };
      }),
    );
  }, [allMonth]);

  const data = useMemo(
    () =>
      api.getDateByPeriod(period, !isDesktop, period === 'Year' ? sumOfMonth : overallHours, month),
    [period, isDesktop, sumOfMonth, overallHours, month],
  );

  const totalHours = useMemo(() => {
    const minutes =
      overallMinutes?.filterDate.reduce<number>((sum, prev) => {
        return sum + parseInt(prev.workedMinutes, 10);
      }, 0) ?? 0;

    return getTime(minutes);
  }, [overallMinutes?.filterDate]);

  useEffect(() => {
    if (userRole === userRoles.admin && !currentUser?.id) {
      return;
    }
    const overall = {
      year: year.toString(),
      id: userRole === userRoles.admin ? currentUser?.id : undefined,
      type: period.toLowerCase(),
      month,
    };
    dispatch(
      userRole === userRoles.admin ? loadOverallMinutes(overall) : loadUserOverallMinutes(overall),
    );
  }, [currentUser?.id, dispatch, userRole, period, year, month]);

  const isDashboardVisible = useSelector(blurSelector);

  useEffect(() => {
    const dateRange: IDateRange = { from: null, to: null };

    if (period === Periods.Year) {
      dateRange.from = startOfYear(new Date(year, 0));
      dateRange.to = endOfYear(new Date(year, 0));
    } else if (period === Periods.Month) {
      dateRange.from = startOfMonth(new Date(year, month));
      dateRange.to = endOfMonth(new Date(year, month));
    } else {
      dateRange.from = startOfWeek(new Date(), { weekStartsOn: 1 });
      dateRange.to = endOfWeek(new Date(), { weekStartsOn: 1 });
    }

    dispatch(
      userRole === userRoles.admin
        ? loadResultReports({
            userId: currentUser?.id,
            date: dateRange,
            limit: 100,
          })
        : loadReportsByDate({
            limit: 100,
            date: dateRange,
          }),
    );
  }, [currentUser?.id, dispatch, month, period, userRole, year]);

  const { reports: userReports } = useSelector(reportsSelector);
  const adminResults = useSelector((state: IApplicationState) => paginatedReportsSelector(state));
  const { reports: adminReports } = adminResults || { reports: [] as IReport[] };
  const reports = userRole === userRoles.admin ? adminReports : userReports;

  const reportData = useMemo(() => {
    const toReturn: IOverallCardReport[] = [];
    const getPeriodKey = (workedDay: Date) => {
      switch (period) {
        case Periods.Year:
          return formatDate(new Date(workedDay), 'MMM');
        case Periods.Month:
          return new Date(workedDay).getDate().toString();
        case Periods.Week:
          return formatDate(new Date(workedDay), 'EEE');
        default:
          return '';
      }
    };

    const allPeriodReports =
      userRole === userRoles.admin && Array.isArray(reports)
        ? reports.reduce((acc: IOverallCardReport[], rep: IReport) => {
            const temp: IOverallCardReport[] = [];

            if (rep?.project?.projectName) {
              temp.push({
                key: getPeriodKey(rep.workedDay),
                [rep?.project?.projectName]: rep.workedMinutes / 60,
              });
            } else {
              temp.push({
                key: getPeriodKey(rep.workedDay),
                vacation: rep.workedMinutes / 60,
              });
            }

            return [...acc, ...temp];
          }, [])
        : Object.values(reports).reduce((acc: IOverallCardReport[], el) => {
            const temp: IOverallCardReport[] = [];

            el.forEach((rep: IReport) => {
              if (rep?.project?.projectName) {
                temp.push({
                  key: getPeriodKey(rep.workedDay),
                  [rep?.project?.projectName]: rep.workedMinutes / 60,
                });
              } else {
                temp.push({
                  key: getPeriodKey(rep.workedDay),
                  vacation: rep.workedMinutes / 60,
                });
              }
            });

            return [...acc, ...temp];
          }, []);

    const mergedReports: IOverallCardReport[] = Object.values(
      allPeriodReports.reduce((acc: Record<string, IOverallCardReport>, el: IOverallCardReport) => {
        const { key, ...rest } = el;
        if (!acc[key]) {
          acc[key] = { key, ...rest };
        } else {
          Object.keys(rest).forEach((field) => {
            acc[key][field] = Number(acc[key][field] || 0) + Number(rest[field]);
          });
        }
        return acc;
      }, {}),
    );

    const usedDays: string[] = mergedReports.map((el: IOverallCardReport) => el.key);

    if (period === Periods.Year) {
      const months = dateUtils.getMonths(true).map((yearMonth) => {
        if (usedDays.includes(yearMonth)) {
          return mergedReports.filter((el: IOverallCardReport) => el.key === yearMonth)[0];
        }
        return {
          key: yearMonth,
        };
      }) as IOverallCardReport[];

      toReturn.push(...months);
    } else if (period === Periods.Month) {
      const days: IOverallCardReport[] = Array(getDaysInMonth(new Date(year, month)))
        .fill(0)
        .map((_, idx) => {
          if (usedDays.includes((idx + 1).toString())) {
            return mergedReports.filter(
              (el: IOverallCardReport) => el.key === (idx + 1).toString(),
            )[0];
          }
          return {
            key: `${idx + 1}`,
          };
        }) as IOverallCardReport[];

      toReturn.push(...days);
    } else if (period === Periods.Week) {
      const days = dateUtils.getWeekDays(true).map((day) => {
        if (usedDays.includes(day)) {
          return mergedReports.filter((el: IOverallCardReport) => el.key === day)[0];
        }
        return {
          key: day,
        };
      }) as IOverallCardReport[];

      toReturn.push(...days);
    }

    return toReturn;
  }, [userRole, reports, period, year, month]);

  return {
    data,
    totalHours,
    isDesktop,
    isVisible: !isDashboardVisible,
    reports: reportData,
  };
};

export default useOverallCard;
