import useStyles from '@/components/Cards/RevenueCard/Chart/styles';
import React, { useEffect, useRef, useState } from 'react';
import { TooltipProps } from 'recharts/types/component/Tooltip';
import clsx from 'clsx';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

function RevenueCardTooltip<TValue extends ValueType, TName extends NameType>({
  active,
  payload,
  period,
  coordinate,
  viewBox,
}: TooltipProps<TValue, TName> & { period?: string }) {
  const styles = useStyles({ chartHeight: 189 });

  const tooltipRef = useRef<HTMLDivElement>(null);
  const [tooltipWidth, setTooltipWidth] = useState<number>(0);

  useEffect(() => {
    if (tooltipRef.current) {
      setTooltipWidth(tooltipRef?.current?.offsetWidth);
    }
  }, [payload]);

  if (active) {
    const point = payload ? payload[0] : undefined;
    const salaries = point?.payload;
    const { current, prev } = salaries;

    const isTooltipLeft =
      viewBox &&
      viewBox.x &&
      viewBox.width &&
      coordinate &&
      coordinate.x &&
      viewBox.x + viewBox.width - coordinate.x <= tooltipWidth + 10;

    return (
      <div ref={tooltipRef}>
        {(Boolean(current) || Boolean(prev)) && (
          <>
            <div className={styles.tooltip}>
              {Boolean(current) && (
                <p>
                  <span className={styles.current}>
                    Current {period?.toLowerCase()}: {Math.round(current)}$
                  </span>
                </p>
              )}
              {Boolean(prev) && (
                <p>
                  <span className={styles.previous}>
                    Previous {period?.toLowerCase()}: {Math.round(prev)}$
                  </span>
                </p>
              )}
            </div>
            <div
              className={clsx(
                styles.tooltipTail,
                isTooltipLeft ? styles.tooltipTailRight : styles.tooltipTailLeft,
              )}
            />
          </>
        )}
      </div>
    );
  }

  return null;
}

export default RevenueCardTooltip;
