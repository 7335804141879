import { ValueType, NameType } from 'recharts/types/component/DefaultTooltipContent';
import { TooltipProps } from 'recharts/types/component/Tooltip';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { formatMinutesToHoursPeriod } from '@/utils';
import useStyles from './chart-style';

export default function ChartTooltip<TValue extends ValueType, TName extends NameType>({
  payload,
  viewBox,
  coordinate,
}: TooltipProps<TValue, TName>) {
  const classes = useStyles({ chartHeight: 189 });

  const tooltipRef = useRef<HTMLDivElement>(null);
  const [tooltipWidth, setTooltipWidth] = useState<number>(0);

  useEffect(() => {
    if (tooltipRef.current) {
      setTooltipWidth(tooltipRef?.current?.offsetWidth);
    }
  }, [payload]);

  const isTooltipLeft =
    viewBox &&
    viewBox.x &&
    viewBox.width &&
    coordinate &&
    coordinate.x &&
    viewBox.x + viewBox.width - coordinate.x <= tooltipWidth + 10;

  return (
    <div ref={tooltipRef}>
      <div className={classes.tooltip}>
        {payload?.map((el, idx) => (
          <p key={`tooltip-hint-${idx}`}>{`${el.name}: ${formatMinutesToHoursPeriod(
            Math.round((el.value as number) * 60),
          )}`}</p>
        ))}
      </div>
      <div
        className={clsx(
          classes.tooltipTail,
          isTooltipLeft ? classes.tooltipTailRight : classes.tooltipTailLeft,
        )}
      />
    </div>
  );
}
