import {
  IPaginatedReports,
  IPaginatedResults,
  LoadResultReportsActionProps,
  LoadResultsActionProps,
  IOverallMinutes,
  IUserDateResult,
  IOverallSalary,
  IOverallStatistic,
} from '@/interfaces';
import { reportsFiltersToQuery } from '@/utils';
import auth from '@DevimaSolutions/o-auth';
import { format } from 'date-fns';

const loadReports = async (
  payload: LoadResultReportsActionProps & { page: number },
): Promise<IPaginatedReports> => {
  const response = await auth()
    .axios.get(`/admin/reports`, reportsFiltersToQuery(payload))
    .then(({ data }) => data);

  return response;
};

const loadOverallStatistic = async (payload: IUserDateResult): Promise<IOverallStatistic> => {
  const url = payload.id ? `/admin/overall/statistic/${payload.id}` : `/user/overall/statistic`;
  return auth()
    .axios.get(url, {
      params: {
        type: payload.type,
        year: payload.year,
        month: payload.type === 'month' ? payload.month : undefined,
      },
    })
    .then(({ data }) => data);
};

const loadOverallSalary = async (payload: IUserDateResult): Promise<IOverallSalary> => {
  const response = await auth()
    .axios.get(`/admin/overall/salary/${payload.id}`, {
      params: {
        type: payload.type,
        year: payload.year,
        month: payload.type === 'month' ? payload.month : undefined,
      },
    })
    .then(({ data }) => data);
  return response;
};

const loadUserOverallSalary = async (payload: IUserDateResult): Promise<IOverallSalary> => {
  const response = await auth()
    .axios.get(`/user/overall/salary/`, {
      params: {
        type: payload.type,
        year: payload.year,
        month: payload.type === 'month' ? payload.month : undefined,
      },
    })
    .then(({ data }) => data);
  return response;
};

const loadOverallHours = async (payload: IUserDateResult): Promise<IOverallMinutes> => {
  const response = await auth()
    .axios.get(`/admin/overall/hours/${payload.id}`, {
      params: {
        type: payload.type,
        year: payload.year,
        month: payload.type === 'month' ? payload.month : undefined,
      },
    })
    .then(({ data }) => data);
  return response;
};

const loadUserOverallHours = async (payload: IUserDateResult): Promise<IOverallMinutes> => {
  const response = await auth()
    .axios.get(`/user/overall/hours/`, {
      params: {
        type: payload.type,
        year: payload.year,
        month: payload.type === 'month' ? payload.month : undefined,
      },
    })
    .then(({ data }) => data);
  return response;
};

const loadResults = async ({
  type,
  date,
  page,
  limit,
  orderBy,
  order,
}: LoadResultsActionProps & { page: number }): Promise<IPaginatedResults> => {
  const response = await auth()
    .axios.get(`/admin/results`, {
      params: {
        ...(type && { type }),
        ...(orderBy && { orderBy }),
        ...(order && { order }),
        page,
        limit,
        ...(date?.from &&
          date?.to && { from: format(date.from, 'yyyy-MM-dd'), to: format(date.to, 'yyyy-MM-dd') }),
      },
    })
    .then(({ data }) => data);

  return response;
};

export default {
  loadReports,
  loadResults,
  loadOverallSalary,
  loadOverallHours,
  loadUserOverallSalary,
  loadUserOverallHours,
  loadOverallStatistic,
};
