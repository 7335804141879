import { Avatar, Button, ButtonGroup, Grid, Typography } from '@material-ui/core';

import Header from '@/components/Header';
import ResultSlider from '@/components/ResultSlider';
import SidebarUsers from '@/components/SidebarUsers';
import { RevenueCard, OverallCard, CombinedStatisticsCard, TaxesCard } from '@/components/Cards';
import UserResultTable from '@/components/Tables/UserResultTable';
import { ProjectSelect } from '@/components/FormItems';
import { UsersResultAccordion } from '@/components/Accordions';

import DesktopRangeDatePicker from '@/components/DesktopRangeDatePicker';
import MobileRangeDatePicker from '@/components/MobileRangeDatePicker';
import useDateChanger from '@/hooks/useDateChanger';
import UserTaxesTable from '@/components/Tables/UserTaxesTable';
import useUserResult from './useUserResult';
import useStyles from './styles';

export default function UserResult(): JSX.Element {
  const classes = useStyles();

  const { month, prevMonth, nextMonth, year, nextYear, prevYear, period, changePeriod } =
    useDateChanger();

  const {
    isDesktop,
    selectTab,
    setSelectTab,
    onBackToResults,
    userResult,
    date,
    setDate,
    buttons,
    currentUser,
    projects,
    defaultSelectedProjects,
    selectedProjects,
    fetchReports,
    setSelectedProjects,
    overallSalary,
    overallMinutes,
    searchName,
    setSearchName,
    userRole,
    fetchProjects,
    handleExport,
  } = useUserResult(month, year, period);

  return (
    <Grid container spacing={isDesktop ? 3 : 0}>
      {isDesktop ? (
        <>
          <Grid item xs={12} md={9}>
            <Header title="Results">
              <ButtonGroup color="primary" aria-label="button group">
                {buttons.map((button) => (
                  <Button
                    key={button}
                    className={classes.button}
                    variant={selectTab === button ? 'contained' : 'outlined'}
                    onClick={() => setSelectTab(button)}
                  >
                    {button}
                  </Button>
                ))}
              </ButtonGroup>
            </Header>
            <div className={classes.avatar}>
              <Avatar
                alt={currentUser?.firstName}
                src={currentUser?.photo || '/src/assets/svg/logo.svg'}
                className={classes.currentUser}
              />
              <Typography component="h4" variant="h4">
                {currentUser?.firstName} {currentUser?.lastName}
              </Typography>
              <div className={classes.exportBtn}>
                <Button
                  color="primary"
                  onClick={handleExport}
                  className={classes.button}
                  variant="contained"
                >
                  Export csv
                </Button>
              </div>
            </div>
            {selectTab === buttons[0] && (
              <>
                <div className={classes.datePicker}>
                  <div className={classes.datePickerInput}>
                    <DesktopRangeDatePicker date={date} setDate={setDate} />
                  </div>
                  <div className={classes.projectInputBlock}>
                    {defaultSelectedProjects ? (
                      <ProjectSelect
                        className={classes.projectInput}
                        id="projects"
                        variant="outlined"
                        label="Project"
                        name="projects"
                        data={projects}
                        selectedProjects={selectedProjects}
                        setSelectedProjects={setSelectedProjects}
                        searchName={searchName}
                        setSearchName={setSearchName}
                        fetchProjects={fetchProjects}
                        defaultValue={defaultSelectedProjects}
                      />
                    ) : null}
                  </div>
                </div>
                <UserResultTable
                  user={currentUser}
                  userResult={userResult}
                  fetchData={fetchReports}
                />
              </>
            )}

            {selectTab === buttons[1] && currentUser && <UserTaxesTable user={currentUser} />}

            {selectTab === buttons[2] && (
              <Grid justifyContent="space-between" container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {currentUser && <CombinedStatisticsCard user={currentUser} />}
                    </Grid>
                    <Grid item xs={12}>
                      {currentUser && <TaxesCard />}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={8}>
                  <RevenueCard
                    isDesktop={isDesktop}
                    overallSalary={overallSalary}
                    userRole={userRole}
                    chartHeight={182}
                    month={month}
                    prevMonth={prevMonth}
                    nextMonth={nextMonth}
                    year={year}
                    nextYear={nextYear}
                    prevYear={prevYear}
                    period={period}
                    changePeriod={changePeriod}
                  />
                  <OverallCard
                    isDesktop={isDesktop}
                    userRole={userRole}
                    overallMinutes={overallMinutes}
                    chartHeight={189}
                    year={year}
                    period={period}
                    month={month}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} md={3}>
            <SidebarUsers linkTo="results" />
          </Grid>
        </>
      ) : (
        <>
          <Header
            title="Results"
            mobileButton={{
              title: 'All',
              startIcon: <></>,
              action: onBackToResults,
            }}
          />
          <div className={classes.userInfoBlock}>
            <Typography className={classes.boldText} variant="body1" component="span">
              {currentUser?.fullName}
            </Typography>
            <Typography className={classes.secondaryBoldText} variant="body1" component="span">
              Project
            </Typography>
            {defaultSelectedProjects ? (
              <ProjectSelect
                className={classes.projectInput}
                id="projects"
                variant="outlined"
                name="projects"
                data={projects}
                selectedProjects={selectedProjects}
                setSelectedProjects={setSelectedProjects}
                searchName={searchName}
                setSearchName={setSearchName}
                fetchProjects={fetchProjects}
                defaultValue={defaultSelectedProjects}
              />
            ) : null}
          </div>

          <div className={classes.datePickerBlock}>
            <MobileRangeDatePicker date={date} setDate={setDate} />
          </div>
          <div className={classes.slider}>
            <Typography className={classes.total} variant="body1" component="h5">
              Total
            </Typography>
            <ResultSlider
              time={userResult?.totalHours ?? '0:0'}
              salary={userResult?.totalSalary ? +userResult?.totalSalary : 0}
            />
          </div>
          <UsersResultAccordion
            user={currentUser}
            userResult={userResult}
            fetchData={fetchReports}
          />
        </>
      )}
    </Grid>
  );
}
