import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import ChartTooltip from './ChartTooltip';
import { IChartProps, IOverallCardReport } from '../types';
import useStyles from './chart-style';

const colors = ['#AFA8DE', '#EBB9AE', '#C2D1F6', '#EBCBAE'];

export default function ChartComponent({
  period,
  isDesktop,
  chartHeight,
  overallHours,
  reports,
  ...props
}: IChartProps): JSX.Element {
  const classes = useStyles({ chartHeight });

  if (!reports) return <></>;
  const allProjects: string[] = [];
  reports.forEach((report: IOverallCardReport) => {
    Object.keys(report)
      .filter((el) => el !== 'key' && el !== 'vacation')
      .forEach((el) => {
        if (!allProjects.includes(el)) allProjects.push(el);
      });
  });

  return (
    <ResponsiveContainer className={classes.root} {...props}>
      <BarChart
        data={reports ?? []}
        barCategoryGap={1}
        margin={{
          top: 15,
          right: isDesktop ? 20 : 6,
          left: -15,
          bottom: 25,
        }}
      >
        <XAxis
          fontFamily="Cuprum"
          dataKey="key"
          interval={period === 'Month' && !isDesktop ? 1 : 0}
          tickMargin={10}
        />
        <YAxis
          fontFamily="Cuprum"
          allowDataOverflow
          tickMargin={10}
          textAnchor="end"
          allowDecimals={false}
          tickCount={7}
          interval={0}
          tickFormatter={(value) => `${value}h`}
        />
        <Tooltip content={<ChartTooltip />} />
        <Bar stackId="a" dataKey="vacation" fill="rgb(84, 53, 184)" radius={2} />
        {allProjects.map((el, idx) => (
          <Bar key={`overall-bar-${idx}`} stackId="a" dataKey={el} fill={colors[idx]} radius={2} />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
}
