import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles<Theme, { chartHeight: number | undefined }>((theme) =>
  createStyles({
    root: {
      width: '100%',
      height: (props) => `${props.chartHeight ?? 300}px`,
      marginBottom: '-20px',
    },
    tooltip: {
      padding: '1px 5px',
      borderRadius: '2px',
      backgroundColor: theme.palette.white,
      color: theme.palette.getContrastText(theme.palette.white),
      boxShadow: theme.palette.type === 'light' ? '0 0 15px rgba(80, 78, 97, 0.41)' : '',
      zIndex: 1001,
    },
    tooltipTail: {
      position: 'absolute',
      top: '40%',
      padding: '7px',
      backgroundColor: theme.palette.white,
      transform: 'rotate(45deg)',
      zIndex: -1,
    },
    tooltipTailLeft: {
      left: -2,
    },
    tooltipTailRight: {
      right: -2,
    },
    current: {
      color: theme.palette.primary.main,
    },
    previous: {
      color: theme.palette.info.main,
    },
  }),
);

export default useStyles;
