import {
  CLEAR_RESULT_REPORTS,
  CLEAR_RESULTS,
  IResultsState,
  ResultsAction,
  SET_LOADING_RESULTS,
  STORE_RESULT_REPORTS,
  STORE_RESULTS,
  STORE_OVERALL_SALARY,
  STORE_OVERALL_MINUTES,
  STORE_EARNING_CARD_DATA,
  STORE_STATISTIC,
} from '@/redux/results';
import { endOfQuarter, startOfDay } from 'date-fns';

const initialState: IResultsState = {
  isLoading: false,
  results: {
    paginatedReports: null,
    paginatedResults: null,
    salary: null,
    workedMinutes: null,
    earningCard: {
      overallSalary: null,
      overallMinutes: null,
    },
    statistic: {
      salary: 0,
      minutes: 0,
      expectedSalary: 0,
      overtimeSalary: 0,
      rate: 0,
      limit: 0,
      expectedTaxes: 0,
      expectedTaxesQuarterDate: startOfDay(endOfQuarter(new Date())).toISOString(),
      upcomingTaxEvent: null,
      contractStatus: null,
      contractExpirationDate: null,
      upcomingContractStartDate: null,
      upcomingContractEndDate: null,
    },
  },
  pagination: {
    hasNext: true,
    currentPage: null,
    total: 0,
  },
};

export function resultsReducer(
  state: IResultsState = initialState,
  action: ResultsAction,
): IResultsState {
  switch (action.type) {
    case SET_LOADING_RESULTS:
      return { ...state, isLoading: action.payload };
    case STORE_RESULT_REPORTS: {
      return {
        ...state,
        results: {
          ...state.results,
          paginatedReports: {
            ...action.payload,
            ...(state.results.paginatedReports && {
              reports: state.results.paginatedReports && action.payload.reports,
            }),
          },
        },
        pagination: {
          hasNext: action.payload.hasNext,
          currentPage: action.payload.currentPage,
          total: action.payload.total,
        },
      };
    }
    case CLEAR_RESULT_REPORTS: {
      return {
        ...state,
        results: {
          ...state.results,
          paginatedReports: null,
        },
        pagination: {
          hasNext: true,
          currentPage: null,
          total: 0,
        },
      };
    }
    case STORE_RESULTS: {
      const resultsIdSet = new Set(action.payload.results.map((result) => result.id));
      return {
        ...state,
        results: {
          ...state.results,
          paginatedResults: {
            ...action.payload,
            ...(state.results.paginatedResults && {
              results: state.results.paginatedResults && [
                ...state.results.paginatedResults.results.filter(
                  (result) => !resultsIdSet.has(result.id),
                ),
                ...action.payload.results,
              ],
            }),
          },
        },
        pagination: {
          hasNext: action.payload.hasNext,
          currentPage: action.payload.currentPage,
          total: action.payload.total,
        },
      };
    }
    case CLEAR_RESULTS: {
      return {
        ...state,
        results: {
          ...state.results,
          paginatedResults: null,
        },
        pagination: {
          hasNext: true,
          currentPage: null,
          total: 0,
        },
      };
    }
    case STORE_OVERALL_SALARY: {
      return {
        ...state,
        results: {
          ...state.results,
          salary: action.payload,
        },
      };
    }
    case STORE_OVERALL_MINUTES: {
      return {
        ...state,
        results: {
          ...state.results,
          workedMinutes: action.payload,
        },
      };
    }
    case STORE_EARNING_CARD_DATA: {
      return {
        ...state,
        results: {
          ...state.results,
          earningCard: action.payload,
        },
      };
    }
    case STORE_STATISTIC: {
      return {
        ...state,
        results: {
          ...state.results,
          statistic: action.payload,
        },
      };
    }
    default:
      return state;
  }
}

export default resultsReducer;
